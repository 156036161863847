<template>
  <v-container class="login-container" fluid fill-height>
    <v-layout align-center class="justify-center">
      <v-flex xs12>
        <v-layout align-center column>
          <v-card class="login-card justify-center">
            <v-form
              ref="form"
              v-model="valid"
              @submit.prevent="submit"
              lazy-validation
            >
              <v-img 
                src="@/assets/logo_nrmemories.png"
                contain 
                height="88px"
                class="logo"
              />

              <v-text-field
                :label="$t('email')"
                type="email"
                v-model="email"
                :rules="emailRules"
                prepend-inner-icon="mdi-email-outline"
                required
                autofocus
                outlined
              ></v-text-field>

              <v-text-field
                :label="$t('password')"
                v-model="passwd"
                :rules="passwdRules"
                type="password"
                prepend-inner-icon="mdi-lock-outline"
                required
                outlined
              ></v-text-field>

              <v-btn
                :disabled="loading"
                class="primary"
                large
                block
                type="submit"
                :loading="loading"
              >
                <span>
                  {{ $t('login') }}
                </span>
              </v-btn>
            </v-form>
          </v-card>
        </v-layout>
       </v-flex>
    </v-layout>

  </v-container>
</template>

<style>
  .login-container {
    background-color: #01579B;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .login-card {
    padding: 64px 40px 40px 40px;
    width: 366px;
    height: 540px;
  }

  .errorMessage {
    margin-top: 20px;
    color: red;
    text-align: center;
  }

  .logo {
    margin-bottom: 40px;
  }
</style>

<script>
import { mapActions, mapState } from 'vuex'

import AppActions from '@/store/app/actions-types'
import router from "@/router";

export default {
  name: "Login",

  data() {
    return {
      valid: false,
      loading: false,
      email: null,
      passwd: null,
      emailRules: [
        v => !!v || this.$t('form_rules.mandatory'),
        v => /.+@.+\..+/.test(v) || this.$t('form_rules.invalid_email'),
      ],
      passwdRules: [
        v => !!v || this.$t('form_rules.mandatory'),
        v => (v && v.length > 3) || this.$t('form_rules.min_length', {name: this.$t('password'), min: "4"}),
      ],
      forgotPasswdEmail: null,
    }
  },

  computed: mapState({
    userData: state => state.app.userData
  }),

  methods: {
    ...mapActions('app', [
      AppActions.DO_LOGIN,
      AppActions.DO_LOGOUT,
      AppActions.RESET_PASSWORD,
      AppActions.OPEN_APP_ERROR_MESSAGE
    ]),

    async submit() {
      if (!this.$refs.form.validate()) {
        return;
      }

      this.loading = true;
      const credentials = {email: this.email, password: this.passwd};

      this[AppActions.DO_LOGIN](credentials)
        .then(() => {
          if (!this.userData.roles ||
              (!this.userData.roles.indexOf('ROLE_MASTER') < 0 &&
              !this.userData.roles.indexOf('ROLE_ADMIN') < 0)
          ) {
            console.log(this.userData.roles)
            this[AppActions.OPEN_APP_ERROR_MESSAGE](this.$t('Only master or admin user can access admin page'))
            this[AppActions.DO_LOGOUT]()
            this.loading = false
            return
          }

          router.push('/')
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
  }
};
</script>
